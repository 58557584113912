import { Link } from "react-router-dom";
import useAuthentication from "../contexts/authentication-provider";

const Navigation = () => {
  const { user } = useAuthentication();
  return (
    <header className="container">
      <div className="d-flex flex-wrap justify-content-center py-3 mb-4">
        <Link
          to="/home"
          className="d-flex align-items-center mb-3 mb-md-0 me-sm-auto text-dark text-decoration-none fs-4"
        >
          <i className="bi bi-stars"></i>
          <span>Starlog</span>
        </Link>

        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link to="/about" className="nav-link">
              About
            </Link>
          </li>
          {user && (
            <>
              <li className="nav-item">
                <Link to="/entries" className="nav-link">
                  Entries
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/sign-out" className="nav-link">
                  Sign out
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/profile" className="nav-link">
                  {user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : "Profile"}{" "}
                  <i className="bi bi-person-circle"></i>
                </Link>
              </li>
            </>
          )}
          {!user && (
            <>
              <li className="nav-item">
                <Link to="/sign-up" className="nav-link">
                  Sign up
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/sign-in" className="nav-link">
                  Sign in
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </header>
  );
};
export default Navigation;
