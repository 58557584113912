import axios from "axios";
import jwt_decode from "jwt-decode";
import { JWT_SESSION_STORAGE_NAME } from "../constants";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_SERVICE_API}`,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (request) => {
    const token = sessionStorage.getItem(JWT_SESSION_STORAGE_NAME);
    if (token && typeof token !== "undefined") {
      const jwt: any = jwt_decode(token);
      if (jwt.exp) {
        const now = new Date().getTime() / 1000;
        if (jwt.exp < now) {
          console.error("JWT expired!");
          sessionStorage.removeItem(JWT_SESSION_STORAGE_NAME);
          window.location.replace("/signed-out");
        } else {
          if (request.headers) {
            request.headers["Authorization"] = `Bearer ${token}`;
          } else {
            console.error("Could not set Authorization header in HTTP Request");
          }
        }
      }
    }
    return request;
  },
  async (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        window.location.replace("/signed-out");
      }
    }

    return Promise.reject(error);
  },
);

export const setBearerToken = (token: string): void => {
  sessionStorage.setItem(JWT_SESSION_STORAGE_NAME, token);
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
export const removeBearerToken = (): void => {
  sessionStorage.removeItem(JWT_SESSION_STORAGE_NAME);
  delete api.defaults.headers.common["Authorization"];
};
