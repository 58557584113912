import { useEffect, useState } from "react";

const Loading = ({ size = "small" }) => {
  const [visible, setVisible] = useState<boolean>(false);
  let s = 1;
  switch (size) {
    case "small":
      break;
    case "medium":
      s = 2;
      break;
    case "large":
      s = 3;
  }

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 150);
  }, []);
  return (
    <div className="d-flex justify-content-center">
      <span style={{ fontSize: `${s}rem`, display: visible ? "block" : "none" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            display: "inline-block",
            width: `${s}rem`,
            height: `${s}rem`,
            verticalAlign: "-.125em",
          }}
          className="spin"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z" />
        </svg>
        &nbsp;Loading&hellip;
      </span>
    </div>
  );
};
export default Loading;
