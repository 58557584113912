import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { authorize, getProfile, getUserTokenClaims, unauthorize, register, update } from "../services/user-service";
import { UserType } from "../types";

interface AuthContextType {
  user: UserType | null;
  signIn: (username: string, password: string) => Promise<boolean>;
  signOut: () => Promise<boolean>;
  signUp: (username: string, password: string) => Promise<boolean>;
  updateUser: (firstname?: string, lastname?: string, password?: string) => Promise<boolean>;
}
const AuthContext: React.Context<AuthContextType> = createContext({} as AuthContextType);

// AuthenticationProvider to wrap around our main App.tsx component
export const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  // state to hold current user if signed in
  const userTokenClaims = getUserTokenClaims();
  let _user = null;
  if (userTokenClaims && userTokenClaims.user) {
    _user = userTokenClaims.user;
  }

  const [user, setUser] = useState<UserType | null>(_user);

  // signIn method
  const signIn = async (username: string, password: string): Promise<boolean> => {
    const signedIn = await authorize(username, password);
    if (signedIn) {
      const profile = await getProfile();
      if (profile) {
        setUser(profile);
        return true;
      }
      return false;
    }
    return false;
  };

  // signOut method
  const signOut = async (): Promise<boolean> => {
    const signedOut = await unauthorize();
    if (signedOut) {
      setUser(null);
      return true;
    }
    return false;
  };

  // signUp method
  const signUp = async (username: string, password: string): Promise<boolean> => {
    const signedIn = await register(username, password);
    if (signedIn) {
      const profile = await getProfile();
      if (profile) {
        setUser(profile);
        return true;
      }
      return false;
    }
    return false;
  };

  // updateUser method
  const updateUser = async (firstname?: string, lastname?: string, password?: string): Promise<boolean> => {
    const updated = await update(firstname, lastname, password);
    if (updated) {
      const profile = await getProfile();
      if (profile) {
        setUser(profile);
        return true;
      }
      return false;
    }
    return false;
  };

  // Using React Memo, to ensure that the AuthenticationProvider only update when the user state changes. Otherwise the whole tree under the AuthenticationProvider will re-render children at every state and value change
  const authValues = useMemo(
    () => ({
      user,
      signIn,
      signOut,
      signUp,
      updateUser,
    }),
    [user],
  );

  return <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>;
};

// useAuthentication is a context helper function to be used inside the components, that need information about user authentication
const useAuthentication = () => {
  return useContext(AuthContext);
};
export default useAuthentication;
