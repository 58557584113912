import jwt_decode from "jwt-decode";
import { JWT_SESSION_STORAGE_NAME } from "../constants";
import { UserType } from "../types";
import { api, removeBearerToken, setBearerToken } from "../utils/api";

export const getProfile = async (): Promise<UserType> => {
  const res = await api.get(`/user/profile`);
  return res.data;
};

export const unauthorize = async (): Promise<boolean> => {
  const res = await api.post(`/user/signout`);
  if (res.data) {
    removeBearerToken();
    return true;
  }
  return false;
};

export const authorize = async (username: string, password: string): Promise<boolean> => {
  const res = await api.post(`/user/signin`, { username, password });
  if (res.data) {
    setBearerToken(res.data);
    return true;
  }
  removeBearerToken();
  return false;
};

export const register = async (username: string, password: string): Promise<boolean> => {
  const res = await api.post(`/user/signup`, { username, password });
  if (res.data.token) {
    setBearerToken(res.data.token);
    return true;
  }
  removeBearerToken();
  return false;
};

export const update = async (firstname?: string, lastname?: string, password?: string): Promise<boolean> => {
  const res = await api.post(`/user/update`, { firstname, lastname, password });
  if (res.data.token) {
    setBearerToken(res.data.token);
    return true;
  }
  removeBearerToken();
  return false;
};

export const getUserTokenClaims = (): any => {
  const token = sessionStorage.getItem(JWT_SESSION_STORAGE_NAME);
  if (token && typeof token !== "undefined") {
    const jwt: any = jwt_decode(token);
    return jwt;
  }
  return null;
};
