import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import Loading from "./components/loading";
import Navigation from "./components/navigation";
import { AuthenticationProvider } from "./contexts/authentication-provider";

const HomePage = loadable(() => import("./pages/home-page"), {
  fallback: <Loading size="large" />,
});
const AboutPage = loadable(() => import("./pages/about-page"), {
  fallback: <Loading size="large" />,
});
const SignInPage = loadable(() => import("./pages/sign-in-page"), {
  fallback: <Loading size="large" />,
});
const SignOutPage = loadable(() => import("./pages/sign-out-page"), {
  fallback: <Loading size="large" />,
});
const SignedOutPage = loadable(() => import("./pages/signed-out-page"), {
  fallback: <Loading size="large" />,
});
const SignUpPage = loadable(() => import("./pages/sign-up-page"), {
  fallback: <Loading size="large" />,
});
const ProfilePage = loadable(() => import("./pages/profile-page"), {
  fallback: <Loading size="large" />,
});
const EntriesPage = loadable(() => import("./pages/entries-page"), {
  fallback: <Loading size="large" />,
});
const EntryPage = loadable(() => import("./pages/entry-page"), {
  fallback: <Loading size="large" />,
});

const EditEntryPage = loadable(() => import("./pages/edit-entry-page"), {
  fallback: <Loading size="large" />,
});

const App = () => {
  return (
    <Router>
      <AuthenticationProvider>
        <Navigation />
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/home">
            <Redirect to="/"></Redirect>
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/sign-in">
            <SignInPage />
          </Route>
          <Route path="/sign-out">
            <SignOutPage />
          </Route>
          <Route path="/signed-out">
            <SignedOutPage />
          </Route>
          <Route path="/sign-up">
            <SignUpPage />
          </Route>
          <Route path="/profile">
            <ProfilePage />
          </Route>
          <Route path="/entries" exact>
            <EntriesPage />
          </Route>
          <Route path="/entries/:entryId">
            <EntryPage />
          </Route>
          <Route path="/edit/entries/:entryId">
            <EditEntryPage />
          </Route>
        </Switch>
      </AuthenticationProvider>
    </Router>
  );
};

export default App;
